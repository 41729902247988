import React, {useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import {useAuth} from "../Provider/AuthProvider";


export default function Navigation() {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const {logout, isAdmin, user} = useAuth()

    const handleLogut = (e) => {
        e.preventDefault()
        logout()
    }

    return (
        <nav
            className="navbar navbar-expand-lg navbar-light rounded">
            <div className="container-fluid">
                <Link to={'/'} className="navbar-brand font-weight-bolder ms-3" rel="tooltip"
                      title="ProClean Flensburg" data-placement="bottom">
                    ProClean
                </Link>
                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navigation"
                        aria-controls="navigation" aria-expanded={!isNavCollapsed ? true : false}
                        aria-label="Toggle navigation" onClick={handleNavCollapse}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navigation">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/'}>Buchungen</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/apartments'}>Wohnungen</NavLink>
                        </li>
                        {isAdmin() ?
                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/users'}>Benutzer</NavLink>
                            </li>
                            : ''}
                    </ul>
                    <div className={"d-flex align-items-end"}>
                        <ul className={"navbar-nav"}>
                            <li className={"nav-item"}>
                                <span className={"nav-link"}>{user.display_name}</span> </li>
                            <li className={"nav-item text-right"}>
                                <a className={"nav-link"} href={"/auth/login"} onClick={handleLogut}>Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
        ;
}