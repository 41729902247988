import React, {useState} from 'react';
import {useAuth} from "../Provider/AuthProvider";
import {useNavigate} from "react-router";
import config from "../config";
import axios from "axios";


const Login = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const {setUser} = useAuth();
    const navigate = useNavigate();


    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        // You can pass formData as a fetch body directly:
        axios.postForm(config.backendUrl() + '/token', formData)
            .then(async function (response) {
                setErrorMessage('')
                await setUser(response.data)
                navigate("/", {replace: true});
            }).catch(function (error) {
            if (error.response.status === 401) {
                setErrorMessage("Benutzername oder Passwort falsch")
            } else {
                setErrorMessage(error.message)
            }
        })
    }

    return <>
        <div className={"container mt-5"}>
            <div className={"row"}>
                <div className={"col-xs-12 col-md-6 text-center"}>
                    <h3>ProClean Login</h3>
                    <form method={"post"} className={"postion-relative"} onSubmit={handleSubmit}>
                        <div className={"mb-3"}>
                            <label htmlFor="username" className="form-label">Benutzername</label>
                            <input className={"form-control" + (errorMessage !== '' ? " is-invalid" : "")} type={"text"}
                                   id={"username"}
                                   name={"username"}/>
                        </div>
                        <div className={"mb-3"}>
                            <label htmlFor="password" className="form-label">Passwort</label>
                            <input className={"form-control" + (errorMessage !== '' ? " is-invalid" : "")}
                                   type={"password"}
                                   name={"password"}/>
                        </div>
                        <button type={"submit"} className={"btn btn-primary"}>Anmelden</button>
                    </form>
                    {errorMessage !== '' &&
                        <p className={"text-danger"}>{errorMessage}</p>
                    }
                </div>
            </div>
        </div>
    </>

};

export default Login;