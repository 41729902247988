import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../Provider/AuthProvider";

export const ProtectedRoute = (props) => {
    const {user} = useAuth();

    // Check if the user is authenticated
    if (!user) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/auth/login" />;
    }

    // If authenticated, render the child routes
    return <Outlet />
};