import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

//import {useAuth} from "../Provider/AuthProvider";
import {ProtectedRoute} from "../Components/ProtectedRoute";
import Dashboard from "../Components/Dashboard";
import Apartments from "../Components/Apartments";
import Login from "../Components/Login";
import Users from "../Components/Users";

const Routes = () => {
    //const {user} = useAuth();
    // Route configurations go here


    const routesForNotAuthenticatedOnly = [
        {
            path: "/auth/",
            children: [
                {
                    path: "login",
                    element: <Login />,
                }
            ]
        }
    ];

    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>,
            children: [
                {
                    path: "",
                    element: <Dashboard/>,
                },
                {
                    path: "apartments",
                    element: <Apartments/>,
                },
                {
                    path: "users",
                    element: <Users/>,
                },
                {
                    path: "logout",
                    element: <div>Logout</div>,
                },
            ],
        },
    ];

    const router = createBrowserRouter([
        ...routesForAuthenticatedOnly,
        ...routesForNotAuthenticatedOnly
    ]);

    return <RouterProvider router={router}/>;

};

export default Routes;
