import React, {useEffect, useState} from 'react';
import Navigation from "./Navigation";
import {useAuth} from "../Provider/AuthProvider";
import axios from "axios";
import config from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Users() {

    const [users, setUsers] = useState([])
    const [detailUser, setDetailUser] = useState()
    const [showModal, setShowModal] = useState(false)
    const [fetchingUsers, setFetchingUsers] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [updatesSaved, setUpdatesSaved] = useState(true)
    const {user, logout, isAdmin} = useAuth()

    useEffect(() => {
        setUpdatesSaved(false)
        if (!fetchingUsers && updatesSaved) {
            setFetchingUsers(true)
            axios.get(config.backendUrl() + '/users', {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            }).then(function (response) {
                setUsers(response.data)
                setErrorMessage('')
            }).catch(function (error) {
                console.log(error)
                if (error.response.status === 401) {
                    logout()
                }
                setErrorMessage(error.message)
            }).finally(() => {
                    setFetchingUsers(false)
                    setUpdatesSaved(false)
                }
            )
        }
    }, [user, updatesSaved, logout, fetchingUsers]);


    const openUserModal = (user) => {
        if (user !== undefined) {
            setDetailUser(user)
        } else {
            setDetailUser(null)
        }
        setShowModal(true)
    }

    if (fetchingUsers) {
        return <>
            <Navigation/>
            <div className="container-fluid mt-3 text-center mt-5">
                <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
            </div>
        </>
    }


    if (errorMessage) {
        return <>
            <Navigation/>
            <div className="container-fluid mt-3 text-center mt-5">
                <div className="error-decor">{errorMessage}</div>
            </div>
        </>
    }


    function UsersTable() {

        return <>
            <div className={"card"}>
                <div className={"card-header"}>
                    <div className={"card-header d-flex justify-content-between"}><h1>Benutzer</h1>
                        <button className={"btn btn-primary"} onClick={() => openUserModal()}><FontAwesomeIcon
                            icon={"add"} className={"me-2"}
                        />Benutzer hinzufügen
                        </button>
                    </div>
                </div>
                <div className={"card-body"}>
                    <div className={"apartments-table table-reponsive"}>
                        {users.length === 0 ? <p>Legen Sie erst eine Wohnung an</p> :
                            <table className={"table table-striped align-items-center "}>
                                <thead>
                                <tr>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>#</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Benutzername</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>email</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Name</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Rollen</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>aktiv</th>
                                    <th>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {users.map((current_user) => {
                                    return <tr key={current_user.id + 'userrow'}>
                                        <td><p className={"text-xs font-weight-bold mb-0"}>{current_user.id}</p></td>
                                        <td><p className={"text-xs font-weight-bold mb-0"}>{current_user.username}</p>
                                        </td>
                                        <td><p className={"text-xs font-weight-bold mb-0"}>{current_user.email}</p></td>
                                        <td><p
                                            className={"text-xs font-weight-bold mb-0"}>{current_user.display_name}</p>
                                        </td>
                                        <td><p
                                            className={"text-xs font-weight-bold mb-0"}>{current_user.roles.map((role) => {
                                            return <span key={"role" + current_user.id + role}
                                                         className={"me-1"}>{role}</span>
                                        })}</p></td>
                                        <td><p
                                            className={"text-xs font-weight-bold mb-0"}>{current_user.disabled ? 'nein' : 'ja'}</p>
                                        </td>
                                        <td>
                                            <button className={"btn btn-primary btn-sm me-3"}
                                                    onClick={() => openUserModal(current_user)}>
                                                <FontAwesomeIcon
                                                    icon={"fa-solid fa-pen-to-square"}/></button>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </>
    }

    function UserModal() {

        const [changes, setChanges] = useState(false)
        const [roles, setRoles] = useState(detailUser && detailUser.roles ? detailUser.roles : ["ROLE_OWNER"])
        const [username, setUsername] = useState(detailUser && detailUser.username ? detailUser.username : '')
        const [email, setEmail] = useState(detailUser && detailUser.email ? detailUser.email : '')
        const [displayName, setDisplayName] = useState(detailUser && detailUser.display_name ? detailUser.display_name : '')
        const [password, setPassword] = useState('')
        const [passwordRepeat, setPasswordRepeat] = useState('')
        const [passwordInvalid, setPasswordInvalid] = useState(false)

        useEffect(() => {
            if (password !== passwordRepeat) {
                setChanges(false)
                setPasswordInvalid(true)
            } else {
                setChanges(true)
                setPasswordInvalid(false)
            }
        }, [password, passwordRepeat]);

        if (!showModal) {
            return <></>
        }
        const handleChanges = () => {
            if (!detailUser) {
                axios.post(config.backendUrl() + '/users', {
                    username: username,
                    display_name: displayName,
                    password: password,
                    email: email,
                    roles: roles
                }).then((response) => {
                    setShowModal(false)
                    setUpdatesSaved(true)
                }).catch((error) => {

                })
            } else {
                axios.put(config.backendUrl() + '/users', {
                    id: detailUser.id,
                    username: username,
                    display_name: displayName,
                    email: email,
                    roles: roles,
                    password: password
                }).then((response) => {
                    setShowModal(false)
                    setUpdatesSaved(true)
                }).catch((error) => {

                })
            }
        }


        return <div className={"container"}>
            <div className={"modal fade " + (showModal ? 'show' : '')} tabIndex="-1" style={{
                display: showModal ? 'block' : 'none'
            }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {username}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className={"table-responsive"}>
                                <table className={"table"}>
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Benutzername:</p>
                                        </td>
                                        <td>
                                            <div className={"input-group"}>
                                                <input type={"text"} className={"form-control"} value={username}
                                                       onChange={(e) => {
                                                           setUsername(e.target.value)
                                                           setChanges(true)
                                                       }}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Email:</p>
                                        </td>
                                        <td>
                                            <div className={"input-group"}>
                                                <input type={"text"} className={"form-control"} value={email}
                                                       onChange={(e) => {
                                                           setEmail(e.target.value)
                                                           setChanges(true)
                                                       }}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Anzeigename:</p>
                                        </td>
                                        <td>
                                            <div className={"input-group"}>
                                                <input type={"text"} className={"form-control"} value={displayName}
                                                       onChange={(e) => {
                                                           setDisplayName(e.target.value)
                                                           setChanges(true)
                                                       }}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Rollen:</p>
                                        </td>
                                        <td>
                                            <div className={"input-group"}>
                                                <select className="form-control" disabled={!isAdmin() || (detailUser && detailUser.username === user.username) } onChange={(e) => {
                                                    const newRole = e.target.value
                                                    const newRoles = [newRole === '1' ? "ROLE_ADMIN": "ROLE_OWNER"]
                                                    setRoles(newRoles)
                                                }} value={roles[0] === "ROLE_OWNER" ? 0 : 1}>
                                                  <option value="0">ROLE_OWNER</option>
                                                  <option value="1">ROLE_ADMIN</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Passwort:</p>
                                        </td>
                                        <td>
                                            <div className={"input-group"}>
                                                <input type={"password"}
                                                       className={"form-control " + (passwordInvalid ? 'is-invalid' : '')}
                                                       value={password}
                                                       onChange={(e) => {
                                                           setPassword(e.target.value)
                                                       }}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Passwort wiederholen:</p>
                                        </td>
                                        <td>
                                            <div className={"input-group"}>
                                                <input type={"password"}
                                                       className={"form-control " + (passwordInvalid ? 'is-invalid' : '')}
                                                       value={passwordRepeat}
                                                       onChange={(e) => {
                                                           setPasswordRepeat(e.target.value)
                                                       }}/>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
                                setShowModal(false)
                            }}>Schließen
                            </button>
                            <button type="button" className={"btn btn-primary"} disabled={!changes}
                                    data-bs-dismiss="modal" onClick={handleChanges}>Änderungen übernehmen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <>
            <Navigation/>
            <div className="container-fluid mt-3">
                <UsersTable/>
            </div>
            <UserModal/>
        </>
    );
}