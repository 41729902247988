import React, {useEffect, useState} from 'react';
import Navigation from "./Navigation";
import {useAuth} from "../Provider/AuthProvider";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import config from "../config";

library.add(fas);

export default function Apartments() {

    const [apartments, setApartments] = useState([])
    const [detailApartment, setDetailApartment] = useState(null)
    const [fetchingApartments, setFetchingApartments] = useState(false)
    const [updatesSaved, setUpdatesSaved] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const {user, logout} = useAuth()

    useEffect(() => {
        if (!fetchingApartments && updatesSaved) {
            setFetchingApartments(true)
            axios.get(config.backendUrl() + '/apartments', {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            }).then(function (response) {
                setApartments(response.data)
            }).catch(function (error) {
                console.log(error)
                if (error.response.status === 401) {
                    logout()
                }
                setErrorMessage(error.message)
            }).finally(() => {
                    setFetchingApartments(false)
                    setUpdatesSaved(false)
                }
            )
        }
    }, [user, updatesSaved, fetchingApartments, logout]);

    const openApartmentModal = (apartment) => {
        if (apartment !== undefined) {
            setDetailApartment(apartment)
        } else {
            setDetailApartment(null)
        }
        setShowModal(true)
    }

    if (fetchingApartments) {
        return <>
            <Navigation/>
            <div className="container-fluid mt-3 text-center mt-5">
                <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
            </div>
        </>
    }

    if (errorMessage) {
        return <>
            <Navigation/>
            <div className="container-fluid mt-3 text-center mt-5">
                <div className="error-decor">{errorMessage}</div>
            </div>
        </>
    }

    function ListItems() {
        return apartments.map((apartment) => {
            return <tr key={"apartment" + apartment.id}>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.id}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.name}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.street}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.zip}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.city}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.check_in}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.check_out}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.smoobu ? 'ja' : 'nein'}</p></td>
                <td><p className={"text-xs font-weight-bold mb-0"}>{apartment.laundry ? 'ja' : 'nein'}</p>
                </td>
                <td>
                    <button className={"btn btn-primary btn-sm me-3"} onClick={() => openApartmentModal(apartment)}>
                        <FontAwesomeIcon
                            icon={"fa-solid fa-pen-to-square"}/></button>
                </td>
            </tr>
        })
    }

    function ApartmentsTable() {

        return <>
            <div className={"card mb-3"}>
                <div className={"card-header d-flex justify-content-between"}><h1>Wohnungen</h1>
                    <button className={"btn btn-primary"} onClick={() => openApartmentModal()}><FontAwesomeIcon
                        icon={"add"} className={"me-2"}
                    />Wohnung hinzufügen
                    </button>
                </div>
                <div className={"card-body"}>
                    <div className={"apartments-table table-reponsive"}>
                        {apartments.length === 0 ? <p>Legen Sie erst eine Wohnung an</p> :
                            <table className={"table table-striped align-items-center "}>
                                <thead>
                                <tr>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>#</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Name</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Straße</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>PLZ</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Ort</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Check-In</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Check-Out</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Smoobu</th>
                                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}>Wäschepaket</th>
                                    <th>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <ListItems/>
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </>
    }


    function ApartmentModal() {

        const [newApartment] = useState(!detailApartment)
        const [laundry, setLaundry] = useState(detailApartment ? detailApartment.laundry : false)
        const [smoobu] = useState(detailApartment ? detailApartment.smoobu : false)
        const [street, setStreet] = useState(detailApartment ? detailApartment.street : '')
        const [name, setName] = useState(detailApartment ? detailApartment.name : '')
        const [zip, setZip] = useState(detailApartment ? detailApartment.zip : '')
        const [city, setCity] = useState(detailApartment ? detailApartment.city : '')
        const [checkout, setCheckout] = useState(detailApartment && detailApartment.check_out ? parseInt(detailApartment.check_out) : '')
        const [checkin, setCheckin] = useState(detailApartment && detailApartment.check_in ? parseInt(detailApartment.check_in) : '')
        const [changes, setChanges] = useState(false)

        if (!showModal) {
            return <></>
        }

        const handleChanges = () => {
            if (newApartment) {
                axios.post(config.backendUrl() + '/apartments', {
                    name: name,
                    laundry: laundry,
                    zip: zip,
                    city: city,
                    street: street,
                    check_out: checkout + ':00:00',
                    check_in: checkin + ':00:00'
                }).then((response) => {
                    setShowModal(false)
                    setUpdatesSaved(true)
                }).catch((error) => {

                })
            } else {
                axios.put(config.backendUrl() + '/apartments', {
                    id: detailApartment.id,
                    name: name,
                    laundry: laundry,
                    zip: zip,
                    city: city,
                    street: street,
                    check_in: checkin + ':00:00',
                    check_out: checkout + ':00:00'
                }).then((response) => {
                    setShowModal(false)
                    setUpdatesSaved(true)
                }).catch((error) => {

                })
            }
        }

        const handleLaundryChange = () => {
            const newLaundry = !laundry
            setLaundry(newLaundry)
            setChanges(true)
        }

        const handleStreetChange = (e) => {
            const newStreet = e.target.value
            setStreet(newStreet)
            setChanges(true)
        }

        const handleZipChange = (e) => {
            const newZip = e.target.value
            setZip(newZip)
            setChanges(true)
        }

        const handleCityChange = (e) => {
            const newValue = e.target.value
            setCity(newValue)
            setChanges(true)
        }

        const handleNameChange = (e) => {
            const newValue = e.target.value
            setName(newValue)
            setChanges(true)
        }


        const handleCheckinChange = (e) => {
            const newValue = e.target.value
            setCheckin(parseInt(newValue))
            setChanges(true)
        }


        const handleCheckoutChange = (e) => {
            const newValue = e.target.value
            setCheckout(parseInt(newValue))
            setChanges(true)
        }


        return <div className={"container"}>
            <div className={"modal fade " + (showModal ? 'show' : '')} tabIndex="-1" style={{
                display: showModal ? 'block' : 'none'
            }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {name}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className={"table-responsive"}>
                                <table className={"table"}>
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Name:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{name}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"text"} className={"form-control"} value={name}
                                                           onChange={handleNameChange}/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Straße:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{street}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"text"} className={"form-control"} value={street}
                                                           onChange={handleStreetChange}/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>PLZ:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{zip}</p>
                                                :
                                                <div className={"form-group"}>
                                                    <div className={"input-group"}>
                                                        <input type={"text"} className={"form-control"} value={zip}
                                                               onChange={handleZipChange}/>
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Ort:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{city}</p>
                                                :
                                                <div className={"form-group"}>
                                                    <div className={"input-group"}>
                                                        <input type={"text"} className={"form-control"} value={city}
                                                               onChange={handleCityChange}/>
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Checkin:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{checkin}</p>
                                                :
                                                <div className={"form-group"}>
                                                    <div className={"input-group"}>
                                                        <input type={"number"} max={24} className={"form-control"} value={checkin}
                                                               onChange={handleCheckinChange}/>
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Checkout:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{checkout}</p>
                                                :
                                                <div className={"form-group"}>
                                                    <div className={"input-group"}>
                                                        <input type={"number"} max={24} className={"form-control"} value={checkout}
                                                               onChange={handleCheckoutChange}/>
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Wäschepaket:</p>
                                        </td>
                                        <td>
                                            <div className="font-weight-bold mb-0 form-check form-switch">
                                                <input className="form-check-input" type="checkbox"
                                                       id="flexSwitchCheckDefault"
                                                       checked={laundry}
                                                       onChange={handleLaundryChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
                                setShowModal(false)
                            }}>Schließen
                            </button>
                            <button type="button" className={"btn btn-primary"} disabled={!changes}
                                    data-bs-dismiss="modal" onClick={handleChanges}>Änderungen übernehmen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <>
            <Navigation/>
            <div className="container-fluid mt-3">
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ApartmentsTable/>
                    </div>
                </div>
            </div>
            <ApartmentModal/>
        </>
    );
}