import './App.scss';
import React from 'react';

import AuthProvider from "../../Provider/AuthProvider";
import Routes from "../../Routes";


function App() {


    return (
        <div className="App">
            <AuthProvider>
                <Routes/>
            </AuthProvider>
        </div>
    );
}

export default App;
