import React, {useEffect, useState, useRef} from 'react';
import Navigation from "./Navigation";
import axios from "axios";
import {useAuth} from "../Provider/AuthProvider";
import config from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";

import {registerLocale} from "react-datepicker";
import de from 'date-fns/locale/de';


import "react-datepicker/dist/react-datepicker.css";

registerLocale('de', de)

export default function Dashboard() {

    const [apartments, setApartments] = useState([])
    const [days] = useState(365)
    const [fetchingApartments, setFetchingApartments] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [detailBooking, setDetailBooking] = useState(null)
    const [detailApartment, setDetailApartment] = useState(null)
    const [updatesSaved, setUpdatesSaved] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const {user, logout} = useAuth()
    const bookingsTable = useRef(null)

    useEffect(() => {

        if (bookingsTable.current !== null) {
            console.log(bookingsTable.current.scrollTop)
        }

        setUpdatesSaved(false)
        if (!fetchingApartments && user && updatesSaved) {
            setFetchingApartments(true)
            axios.get(config.backendUrl() + '/apartments', {
                headers: {
                    Authorization: 'Bearer ' + user.access_token
                }
            }).then(function (response) {
                setApartments(response.data)
                setErrorMessage('')
            }).catch(function (error) {
                console.log(error)
                if (error.response.status === 401) {
                    logout()
                }
                setErrorMessage(error.message)
            }).finally(() => {
                    setFetchingApartments(false)
                }
            )
        }
    }, [user, updatesSaved, fetchingApartments, logout]);


    if (errorMessage) {
        return <>
            <Navigation/>
            <div className="container-fluid mt-3 text-center mt-5">
                <div className="error-decor">{errorMessage}</div>
            </div>
        </>
    }

    if (fetchingApartments) {
        return <>
            <Navigation/>
            <div className="container-fluid mt-3 text-center mt-5">
                <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>
            </div>
        </>
    }

    if (apartments.length === 0) {
        return <>
            <Navigation/>
            <div className="container-fluid mt-3 text-center mt-5">
                <div className="error-decor">Legen Sie unter dem Menüpunkt Wohnungen zuerst eine Wohnung an</div>
            </div>
        </>
    }

    function ApartmentTable() {


        function getRandomRgb(number) {
            var num = Math.round(0xffffff * (number * 10000));
            var r = num >> 16;
            var g = (num >> 8) & 255;
            var b = num & 255;
            return 'rgba(' + r + ', ' + g + ', ' + b + ', 0.35)';
        }

        function showDetails(apartment, booking) {
            //console.log(bookingsTable)
            //console.log(bookingsTable.current.scrollLeft)
            //setScrollHeight(bookingsTable)
            console.log(bookingsTable.current.scrollTop)

            setDetailBooking(booking)
            setDetailApartment(apartment)
            setShowModal(true)
        }


        const listItems = apartments.map(apartment => {
            const dayItems = function () {

                let daysList = []

                const now = new Date()
                const startDay = new Date(now - ((now % 86400000) + 86400000 * 30))

                for (let i = 0; i < days; i++) {

                    const currentDay = new Date(startDay.getTime() + (i * 86400000))

                    let currentBookings = []
                    let bookingColor = '#ffffff'

                    apartment.bookings.map((booking) => {
                        const arrival = Date.parse(booking.arrival + "T00:00:00+00:00")
                        const departure = Date.parse(booking.departure + "T00:00:00+00:00")
                        if (arrival <= currentDay && departure >= currentDay) {
                            currentBookings.push(booking)
                        }
                        return booking
                    })

                    currentBookings.sort((a, b) => (a.arrival > b.arrival) ? 1 : -1)

                    if (currentBookings.length > 0) {
                        daysList.push(
                            <td key={apartment.id + "" + i} className={""}
                                style={{
                                    borderLeft: "1px solid #CCC",
                                    cursor: "pointer",
                                    padding: 0,
                                    minWidth: "4rem"
                                }}
                            >{currentBookings.map(currentBooking => {
                                return <div className={"text-center"} key={"multibooking" + currentBooking.id} style={{
                                    backgroundColor: getRandomRgb(currentBooking.id),
                                    width: 100 / currentBookings.length + "%",
                                    height: "100%",
                                    display: "block",
                                    float: "left",
                                    paddingTop: "0.5rem",
                                    paddingBottom: "0.5rem"
                                }} onClick={(event) => {
                                    showDetails(apartment, currentBooking)
                                }}>
                                    <p className={"text-xs font-weight-bold mb-0"}>{((currentBooking.adults ? Number.parseInt(currentBooking.adults.toString()) : 0) + (currentBooking.children ? Number.parseInt(currentBooking.children.toString()) : 0))}
                                        {currentBooking.laundry_service || apartment.laundry ? " W" : ''}
                                        {currentBooking.notice.length > 0 && !currentBooking.smoobu ? ' !' : ''}
                                    </p></div>
                            })}

                            </td>
                        )
                    } else {
                        daysList.push(
                            <td key={apartment.id + "" + i} className={""}
                                style={{backgroundColor: bookingColor, borderLeft: "1px solid #CCC"}}>
                            </td>
                        )
                    }
                }
                return daysList
            }

            return <tr key={"aparmentrow" + apartment.id}>
                <td><span
                    className={"text-xs font-weight-bold mb-1"}>{apartment.name}</span>&nbsp;{apartment.smoobu ? '' :
                    <button className={"btn btn-primary btn-sm mb-0"} onClick={() => showDetails(apartment, null)}>
                        <FontAwesomeIcon icon={"fa-solid fa-calendar-plus"}/></button>
                }</td>
                {dayItems()}
            </tr>
        })

        function tableHeader() {
            const headerElements = []
            for (let i = 0; i < days; i++) {
                const now = new Date()
                const currentDay = new Date(now - ((now % 86400000) + 86400000 * 30))
                currentDay.setDate(currentDay.getDate() + i)
                headerElements.push(
                    <th className={"text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"}
                        key={"dateheader" + i}>{currentDay.getDate() + '.' + (currentDay.getMonth() + 1) + '.'}</th>
                )
            }

            return <>{headerElements}</>
        }


        return <>
            <div className="card mb-3">
                <div className={"card-header d-flex justify-content-between"}><h1>Buchungen</h1>
                    <a className={"btn btn-primary"}
                       href={config.backendUrl() + '/csv/bookings?token=' + user.access_token} target={"_blank"}
                       rel={"noreferrer"}><FontAwesomeIcon
                        icon={"download"} className={"me-2"}
                    />CSV herunterladen</a>
                </div>
                <div className={"card-body"}>
                    <div className={"booking-table table-reponsive tableFixHead"}>
                        <table className={"table table-striped align-items-center "}>
                            <thead>
                            <tr>
                                <th></th>
                                {tableHeader()}
                            </tr>
                            </thead>
                            <tbody ref={bookingsTable}>
                            {listItems}
                            </tbody>
                            <thead>
                            <tr>
                                <th></th>
                                {tableHeader()}
                            </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </>
    }

    function BookingModal() {

        const now = new Date()
        const tomorrow = new Date()
        tomorrow.setDate(now.getDate() + 1)

        const [laundry, setLaundry] = useState(detailBooking ? detailBooking.laundry_service : false)
        const [arrival, setArrival] = useState(detailBooking ? new Date(Date.parse(detailBooking.arrival)) : now)
        const [departure, setDeparture] = useState(detailBooking ? new Date(Date.parse(detailBooking.departure)) : tomorrow)
        const [checkin, setCheckin] = useState(detailBooking ? parseInt(detailBooking.check_in) : (detailApartment && detailApartment.check_in ? parseInt(detailApartment.check_in) : 16))
        const [checkout, setCheckout] = useState(detailBooking ? parseInt(detailBooking.check_out) : (detailApartment && detailApartment.check_out ? parseInt(detailApartment.check_out) : 11))
        const [guestName, setGuestName] = useState(detailBooking && detailBooking.guest_name ? detailBooking.guest_name : '')
        const [guestEmail, setGuestEmail] = useState(detailBooking && detailBooking.guest_email ? detailBooking.guest_email : '')
        const [guestPhone, setGuestPhone] = useState(detailBooking && detailBooking.guest_phone ? detailBooking.guest_phone : '')
        const [notice, setNotice] = useState(detailBooking && detailBooking.notice ? detailBooking.notice : '')
        const [adults, setAdults] = useState(detailBooking ? detailBooking.adults : 1)
        const [children, setChildren] = useState(detailBooking ? detailBooking.children : 0)
        const [changes, setChanges] = useState(false)
        const [smoobu] = useState(detailBooking ? detailBooking.smoobu : false)

        if (!detailApartment) {
            return <></>
        }

        const deleteBooking = () => {
            console.log('löschen')
            axios.delete(config.backendUrl() + '/bookings/' + detailBooking.id).then((response) => {
                setShowModal(false)
                setUpdatesSaved(true)
            }).catch((error) => {
                console.log(error)
            })
        }

        const handleChanges = () => {

            if (detailBooking) {
                if (detailBooking.smoobu) {
                    axios.put(config.backendUrl() + '/bookings', {
                        id: detailBooking.id,
                        laundry_service: laundry
                    }).then((response) => {
                        setShowModal(false)
                        setUpdatesSaved(true)
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    axios.put(config.backendUrl() + '/bookings', {
                        id: detailBooking.id,
                        laundry_service: laundry,
                        check_out: checkout + ":00:00",
                        check_in: checkin + ":00:00",
                        arrival: arrival.toISOString().substring(0, 10),
                        departure: departure.toISOString().substring(0, 10),
                        adults: adults,
                        children: children,
                        guest_name: guestName,
                        guest_phone: guestPhone,
                        notice: notice,
                        guest_email: guestEmail,
                    }).then((response) => {
                        setShowModal(false)
                        setUpdatesSaved(true)
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            } else {
                axios.post(config.backendUrl() + '/bookings', {
                    laundry_service: laundry,
                    check_out: checkout + ":00:00",
                    check_in: checkin + ":00:00",
                    arrival: arrival.toISOString().substring(0, 10),
                    departure: departure.toISOString().substring(0, 10),
                    adults: adults,
                    children: children,
                    guest_name: guestName,
                    guest_email: guestEmail,
                    guest_phone: guestPhone,
                    notice: notice,
                    apartment_id: detailApartment.id
                }).then((response) => {
                    setShowModal(false)
                    setUpdatesSaved(true)
                }).catch((error) => {

                })
            }
        }

        const handleLaundryChange = () => {
            const newLaundry = !laundry
            setLaundry(newLaundry)
            setChanges(true)
        }

        const handleAdultsChange = (e) => {
            setAdults(parseInt(e.target.value))
            setChanges(true)
        }

        const handleChildrenChange = (e) => {
            setChildren(parseInt(e.target.value))
            setChanges(true)
        }

        const handleCheckoutChange = (e) => {
            setCheckout(parseInt(e.target.value))
            setChanges(true)
        }

        const handleCheckinChange = (e) => {
            setCheckin(parseInt(e.target.value))
            setChanges(true)
        }

        const handleguestNameChange = (e) => {
            setGuestName(e.target.value)
            setChanges(true)
        }

        const handleguestEmailChange = (e) => {
            setGuestEmail(e.target.value)
            setChanges(true)
        }

        const handleguestPhoneChange = (e) => {
            setGuestPhone(e.target.value)
            setChanges(true)
        }

        const handleNoticeChange = (e) => {
            setNotice(e.target.value)
            setChanges(true)
        }


        return <div className={"container"}>
            <div className={"modal fade " + (showModal ? 'show' : '')} tabIndex="-1" style={{
                display: showModal ? 'block' : 'none'
            }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {detailApartment.name}&nbsp;
                                <small>({arrival.toLocaleDateString()} - {departure.toLocaleDateString()})</small>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className={"table-responsive"}>
                                <table className={"table"}>
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Ankunft:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{arrival.toLocaleDateString()}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <DatePicker className={"form-control"} locale={"de"}
                                                                minDate={new Date()}
                                                                selected={arrival} dateFormat={"dd.MM.yyyy"}
                                                                onChange={(date) => {
                                                                    setArrival(date)
                                                                    setChanges(true)
                                                                }}/>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Abreise:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{departure.toLocaleDateString()}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <DatePicker className={"form-control"} locale={"de"}
                                                                minDate={arrival}
                                                                selected={departure} dateFormat={"dd.MM.yyyy"}
                                                                onChange={(date) => {
                                                                    setDeparture(date)
                                                                    setChanges(true)
                                                                }}/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Checkin:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{checkin}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"number"} max={24} className={"form-control"}
                                                           value={checkin}
                                                           onChange={handleCheckinChange}/>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Checkout:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{checkout}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"number"} max={24} className={"form-control"}
                                                           value={checkout}
                                                           onChange={handleCheckoutChange}/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Erwachsene:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{adults}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"number"}
                                                           max={detailApartment.adults ? detailApartment.adults : 20}
                                                           min={1} className={"form-control"} value={adults}
                                                           onChange={handleAdultsChange}/>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Kinder:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{children}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"number"}
                                                           max={detailApartment.children ? detailApartment.children : 20}
                                                           className={"form-control"} value={children}
                                                           onChange={handleChildrenChange}/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Name:</p>
                                        </td>
                                        <td colSpan={3}>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{guestName}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"text"}
                                                           className={"form-control"} value={guestName}
                                                           onChange={handleguestNameChange}/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Email:</p>
                                        </td>
                                        <td colSpan={3}>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>
                                                    <a href={"mailto:" + guestEmail}
                                                       target={"_blank"} rel={"noreferrer"}>{guestEmail}</a></p>
                                                :
                                                <div>
                                                    <div className={"input-group"}>
                                                        <input type={"text"}
                                                               className={"form-control"} value={guestEmail}
                                                               onChange={handleguestEmailChange}/>

                                                    </div>
                                                    <p className={"text-xs font-weight-bold mb-0 mt-2"}>
                                                        <a href={"mailto:" + guestEmail}
                                                           target={"_blank"} rel={"noreferrer"}>{guestEmail}</a></p>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Telefon:</p>
                                        </td>
                                        <td>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{guestPhone}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <input type={"text"}
                                                           className={"form-control"} value={guestPhone}
                                                           onChange={handleguestPhoneChange}/>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Wäschepaket:</p>
                                        </td>
                                        <td>
                                            <div className="font-weight-bold mb-0 form-check form-switch">
                                                <input className="form-check-input" type="checkbox"
                                                       id="flexSwitchCheckDefault"
                                                       checked={laundry}
                                                       onChange={handleLaundryChange}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className={"text-xs font-weight-bold mb-0"}>Anmerkungen:</p>
                                        </td>
                                        <td colSpan={3}>
                                            {smoobu ?
                                                <p className={"text-xs font-weight-bold mb-0"}>{notice}</p>
                                                :
                                                <div className={"input-group"}>
                                                    <textarea className={"form-control"} value={notice}
                                                              onChange={handleNoticeChange}/>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {!detailBooking || (detailBooking && detailBooking.smoobu) ? '' :
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal"
                                        onClick={() => {
                                            deleteBooking(false)
                                        }}>Löschen
                                </button>
                            }
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
                                setShowModal(false)
                            }}>Schließen
                            </button>
                            <button type="button" className={"btn btn-primary"} disabled={!changes}
                                    data-bs-dismiss="modal" onClick={handleChanges}>Änderungen übernehmen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <>
            <Navigation/>
            <div className="container-fluid mt-3">
                <ApartmentTable/>
            </div>
            <BookingModal/>
        </>

    );
}